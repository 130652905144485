<template>
    <BasePopup class="order-detail" v-bind="$attrs" v-on="$listeners">
        <div class="order-detail__wrap" v-if="order">
            <div class="order-detail__picture" :style="
                order.site && order.site.picture ? `background-image: url(${imageSrc(order.site.picture)})` :
                (order.entity && order.entity.picture ? `background-image: url(${imageSrc(order.entity.picture)})` : '')
            " />
            <div class="order-detail__content">
                <div class="order-detail__content__wrap">
                    <div>
                        <div class="order-detail__title">
                            <span v-if="order.site">{{ order.site.title }}</span>
                            <span v-else-if="order.entity">{{ order.entity.title }}</span>
                            <a :href="`tel:${order.entity.phone}`" v-if="order.entity && order.entity.phone">
                                <v-icon color="primary">mdi-phone</v-icon>
                            </a>
                        </div>
                        <div class="order-detail__date">{{ order.created_at | moment('DD.MM.YYYY HH:mm') }}</div>
                    </div>
                    <div>
                        <div class="order-detail__price">{{ priceFormated(order.full_price) }}</div>
                        <div class="order-detail__number">{{ order.external_id }}</div>
                        <div class="order-detail__status" v-if="statuses[order.status]" :style="`color: ${ statuses[order.status].color }`">
                            {{ statuses[order.status].title }}
                        </div>
                    </div>
                </div>
                <div class="order-detail__payment-error" v-if="lastPayment && lastPayment.additional_data && lastPayment.additional_data.error_description">
                    {{ lastPayment.additional_data.error_description }}
                </div>
            </div>
        </div>
        <div class="order-detail__products" v-if="order && order.products && order.products.length">
            <template v-for="item in order.products">
                <div class="order-detail__product" :key="`order-product-${ item.id }`">
                    <div class="order-detail__product__picture" :style="item.product.picture ? `background-image: url(${imageSrc(item.product.picture)})` : ''"></div>
                    <div class="order-detail__product__content">
                        <div class="order-detail__product__content__wrap">
                            <div>
                                <div class="order-detail__product__title">{{ item.product.title }}</div>
                                <div class="order-detail__product__options" v-if="item.options && item.options.length">{{ options(item) }}</div>
                            </div>
                            <div class="order-detail__product__price">{{ item.quantity }} X {{ priceFormated(item.full_price) }}</div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="order-detail__product">
                <div></div>
                <div class="order-detail__product__content">
                    <div class="order-detail__product__content__wrap">
                        <div class="order-detail__product__title">{{ order.delivery.type === ENTITY_DELIVERY_TYPE.PICKUP ? 'Самовывоз' : 'Доставка' }}</div>
                        <div class="order-detail__product__price">{{ order.delivery.price_by_agreement ? 'По согласованию' : priceFormated(order.delivery_price) }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="order-detail__buttons" v-if="canOnlinePay">
            <BaseButton fill @click.stop="pay">Оплатить</BaseButton>
        </div>
    </BasePopup>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import BaseButton from '../common/BaseButton';
    import BasePopup from '../common/BasePopup';
    import BaseCheckbox from '../common/BaseCheckbox';
    import BaseRadio from '../common/BaseRadio';
    
    import { imageSrc, priceFormated, weightFormated, htmlFormatted } from '../../helpers';
    import { ENTITY_PAYMENT_TYPE, ENTITY_DELIVERY_TYPE } from '@/vars';

    export default {
        name: 'EntityCard',
        components: {
            BaseButton,
            BasePopup,
            BaseCheckbox,
            BaseRadio,
        },
        props: {
            order: {
                type: Object,
                default: () => {}
            }
        },
        data: () => ({
            ENTITY_PAYMENT_TYPE,
            ENTITY_DELIVERY_TYPE,
            imageSrc,
            priceFormated,
            weightFormated,
            htmlFormatted,
            optionsCheckbox: [],
            optionsRadio: {},
        }),
        computed: {
            ...mapState('orders', {
                statuses: state => state.statuses,
            }),
            canOnlinePay() {
                return this.order && this.order.payment && 
                    (!this.lastPayment.status || this.lastPayment.status === 'declined') && 
                    this.order.payment.type === ENTITY_PAYMENT_TYPE.ONLINE && 
                    this.order.entity.setting.payment.allow_online;
            },
            lastPayment() {
                return this.order.payments && this.order.payments[0] ? this.order.payments[0] : {};
            }
        },
        methods: {
            options(product) {
                if(product.options && product.options.length) {
                    return product.options.map(item => item.option.title).join(' • ');
                }
                return '';
            },
            async pay() {
                if(this.canOnlinePay) {
                    if (this.order.entity.setting.payment.type === 'paygine' && this.lastPayment.additional_data.payment_params) {
                        const paygineParams = this.lastPayment.additional_data.payment_params;
                        window.location.href = `${process.env.VUE_APP_PAYGINE_URL}/Purchase?${paygineParams}`;
                    } else if (this.order.entity.setting.payment.type === 'cloudpayments' && this.order.entity.setting.payment.cloudpayments_public_id) {
                        const publicId = this.order.entity.setting.payment.cloudpayments_public_id;
                        const widget = new cp.CloudPayments();
                        const this_ = this;
                        widget.pay('charge',
                            {
                                publicId,
                                description: `Оплата заказа №${ this_.order.external_id }`,
                                amount: this_.order.full_price / 100,
                                currency: "RUB",
                                invoiceId: this_.order.id,
                                accountId: this_.order.user.id,
                            },
                            {
                                onComplete: function (paymentResult, options) {
                                    this_.$emit('update');
                                }
                            }
                        );
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
.order-detail {
    padding: 12px;
    position: relative;
    background: #FFFFFF;
    transition: .2s ease box-shadow;
    border-radius: 24px;

    &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
            
        @media screen and (max-width: 460px) {
            display: block;
        }
    }

    &__picture {
        width: 38%;
        height: 215px;
        overflow: hidden;
        border-radius: 18px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
            
        @media screen and (max-width: 460px) {
            width: 100%;
            margin-top: 24px;
        }
    }

    &__content {
        width: 58%;
        padding: 0 4px;
        margin-top: 14px;
            
        @media screen and (max-width: 460px) {
            width: 100%;
        }

        &__wrap {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
    
    &__price {
        display: inline-block;
        position: relative;
        font-weight: 500;
        white-space: nowrap;
        font-size: 24px;
        line-height: 27px;
    }
    
    &__title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        overflow: hidden;

        > a {
            cursor: pointer;
            margin-left: 10px;
            text-decoration: none;
        }
    }
    
    &__date {
        color: #9E9B98;
        margin: 5px 0;
        font-size: 14px;
        line-height: 14px;
    }

    &__number {
        color: #9E9B98;
        margin: 5px 0;
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        text-align: right;
    }

    &__status {
        color: #9E9B98;
        margin: 5px 0;
        font-size: 14px;
        line-height: 14px;
        text-align: right;
    }

    &__payment-error {
        width: 100%;
        text-align: center;
        background: #ff7972;
        color: #ffffff;
        padding: 10px 20px;
        margin-top: 10px;
        font-size: 12px;
        line-height: 20px;
        border-radius: 12px;
    }
    
    &__products { 
        margin: 10px 0;
        overflow-x: hidden;
        overflow-y: auto; 
        border-top: 1px solid #F5F4F2;
    }

    &__product {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid #F5F4F2;

        &__picture {
            width: 60px;
            height: 60px;
            overflow: hidden;
            border-radius: 16px;
            background-image: url('../../assets/empty-img.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &__content {
            width: calc(100% - 70px);
            padding: 4px 0;

            &__wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
            }
        }
        
        &__title {
            display: -webkit-box;
            overflow: hidden;
            font-size: 16px;
            margin-bottom: 4px;
            line-height: 19px;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
        }
        
        &__options {
            font-size: 12px;
            margin-bottom: 4px;
            line-height: 12px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            color: #9E9B98;
        }
        
        &__price {
            display: inline-block;
            position: relative;
            font-weight: 500;
            white-space: nowrap;
            font-size: 18px;
            line-height: 20px;
        }
    }
}
</style>