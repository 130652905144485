<template>
    <div class="order-card" @click="$emit('click')">
        <div class="order-card__content">
            <div>
                <div class="order-card__title">{{ order.entity.title }}</div>
                <div class="order-card__date">{{ order.created_at | moment('DD.MM.YYYY HH:mm') }}</div>
            </div>
            <div>
                <div class="order-card__price">{{ priceFormated(order.full_price) }}</div>
                <div class="order-card__number">{{ order.external_id }}</div>
                <div class="order-card__status" :style="`color: ${ statuses[order.status].color }`">
                    {{ statuses[order.status].title }}
                </div>
            </div>
        </div>
        <div class="order-card__buttons">
            <BaseButton>Подробнее</BaseButton>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { imageSrc, priceFormated, weightFormated } from '../../helpers';

    import BaseButton from '../common/BaseButton';

    export default {
        name: 'OrderCard',
        components: {
            BaseButton,
        },
        props: {
            order: {
                type: Object,
                default: () => {}
            }
        },
        data: () => ({
            imageSrc,
            priceFormated,
            weightFormated,
        }),
        computed: {
            ...mapState('orders', {
                statuses: state => state.statuses,
            }),
        },
        methods: {
            
        }
    }
</script>

<style lang="scss">
.order-card {
    display: flex;
    padding: 20px;
    position: relative;
    background: #FFFFFF;
    border-radius: 24px;
    flex-direction: column;

    &__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    
    &__price {
        display: inline-block;
        position: relative;
        font-weight: 500;
        white-space: nowrap;
        font-size: 24px;
        line-height: 27px;
    }
    
    &__title {
        display: -webkit-box;
        overflow: hidden;
        font-size: 20px;
        font-weight: 700;
        margin-top: 4px;
        line-height: 25px;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
    
    &__date {
        color: #9E9B98;
        margin: 5px 0;
        font-size: 14px;
        line-height: 14px;
    }

    &__number {
        color: #9E9B98;
        margin: 5px 0;
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        text-align: right;
    }

    &__status {
        color: #9E9B98;
        margin: 5px 0;
        font-size: 14px;
        line-height: 14px;
        text-align: right;
    }

    &__buttons {
        margin-top: 10px;
    }
}
</style>